import cx from 'classnames'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/youtube'

import { type SanityVideoModule } from '@data/sanity/queries/types/modules'
import { getYouTubeVideoId } from '@lib/video'

type YoutubeVideoProps = Pick<SanityVideoModule, 'title'> & {
  youtubeVideoUrl: string
  className?: string
}

const YoutubeVideo = ({
  title,
  youtubeVideoUrl,
  className,
}: YoutubeVideoProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => setIsLoaded(true), [])

  if (!youtubeVideoUrl) {
    return null
  }

  const youtubeVideoId = getYouTubeVideoId(youtubeVideoUrl)
  const youtubeThumbnailUrl = `https://i.ytimg.com/vi/${youtubeVideoId}/maxresdefault.jpg`
  const youtubeVideoEmbedUrl = `https://www.youtube.com/embed/${youtubeVideoId}`

  if (!youtubeVideoId) {
    return null
  }

  return (
    <div className={cx('relative w-full pt-[calc((9/16)*100%)]', className)}>
      <div className="absolute inset-0 rounded-xl overflow-hidden">
        {!isPlaying && (
          <>
            <span className="block relative z-10 pointer-events-none p-3 overflow-hidden text-ellipsis">
              {title}
            </span>
            <div
              onClick={() => setIsPlaying(true)}
              className="absolute cursor-pointer inset-0 after:absolute after:inset-0 after:bg-[linear-gradient(0deg,rgba(0,0,0,0.5)_0%,rgba(255,255,255,0)_30%,rgba(255,255,255,0)_70%,rgba(0,0,0,0.5)_100%)]"
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={youtubeThumbnailUrl} alt={title} />
            </div>
            <svg
              viewBox="0 0 61 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute w-[61px] h-[43px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none"
            >
              <rect x="19" y="7" width="24" height="22" fill="white" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.3647 29.4431V12.2068C30.441 15.086 35.147 17.8674 40.7128 20.8662C36.1222 23.4299 30.441 26.3065 24.3647 29.4431ZM58.2276 3.63437C57.1794 2.24375 55.393 1.16127 53.4912 0.802898C47.901 -0.266112 13.0265 -0.269153 7.43941 0.802898C5.91428 1.09081 4.5562 1.78675 3.38956 2.86801C-1.52611 7.46256 0.0142318 32.1017 1.1991 36.0928C1.69735 37.8204 2.34145 39.0663 3.15263 39.8841C4.19774 40.9653 5.62867 41.7098 7.27229 42.0437C11.875 43.0025 35.5875 43.5385 53.3939 42.1877C55.0345 41.8998 56.4867 41.1313 57.632 40.0041C62.1771 35.4279 61.8672 9.40512 58.2276 3.63437Z"
                fill="#FC0D1B"
              />
            </svg>
          </>
        )}

        {isLoaded && (
          <ReactPlayer
            url={youtubeVideoEmbedUrl}
            width="100%"
            height="100%"
            controls
            volume={1}
            muted
            playing={isPlaying}
            onBuffer={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onEnded={() => setIsPlaying(false)}
          />
        )}
      </div>
    </div>
  )
}

export default YoutubeVideo
