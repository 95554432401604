import cx from 'classnames'
import dynamic from 'next/dynamic'
import { useIntersectionObserver } from 'usehooks-ts'

import { type SanityMuxVideo } from '@data/sanity/queries/types/modules'
import { type MuxPlayerProps, getMuxVideoAspectRatio } from '@lib/video'

const MuxPlayer = dynamic(() => import('./mux-player'))

type MuxVideoProps = Omit<MuxPlayerProps, 'assetDocument'> & {
  video: SanityMuxVideo
}

const MuxVideo = ({
  video,
  autoplay,
  showControls,
  muted,
  loop,
}: MuxVideoProps) => {
  const { ref, isIntersecting } = useIntersectionObserver({
    freezeOnceVisible: true,
    threshold: 0.1,
  })

  const videoAspectRatio = getMuxVideoAspectRatio(video)

  return (
    <div
      ref={ref}
      className={cx('relative w-full z-0 h-full overflow-hidden')}
      style={{
        paddingTop: `${100 / videoAspectRatio}%`,
      }}
    >
      {isIntersecting && (
        <MuxPlayer
          assetDocument={video.asset}
          showControls={showControls}
          autoplay={autoplay}
          muted={muted}
          loop={loop}
          playsInline={autoplay && !showControls}
          videoStyle={{
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
          }}
        />
      )}
    </div>
  )
}

export default MuxVideo
