import { type SanityVideoModule } from '@data/sanity/queries/types/modules'

import MuxVideo from '@components/video/mux-video'
import YoutubeVideo from '@components/video/youtube-video'

type VideoModuleProps = Pick<
  SanityVideoModule,
  'title' | 'type' | 'muxVideo' | 'youtubeVideoUrl'
> & {
  className?: string
}

const VideoModule = ({
  title,
  type,
  muxVideo,
  youtubeVideoUrl,
  className,
}: VideoModuleProps) => {
  switch (type) {
    case 'mux': {
      if (!muxVideo) {
        return null
      }

      return (
        <MuxVideo
          video={muxVideo}
          showControls
          muted={false}
          autoplay={false}
          loop={false}
        />
      )
    }

    case 'youtube': {
      if (!youtubeVideoUrl) {
        return null
      }

      return (
        <YoutubeVideo
          youtubeVideoUrl={youtubeVideoUrl}
          title={title}
          className={className}
        />
      )
    }

    default: {
      return null
    }
  }
}

export default VideoModule
